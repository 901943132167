import React, { useRef, useState, useEffect, useLayoutEffect, MutableRefObject } from 'react'
import { CanvasEngine } from '@/canvas/canvas/engine'
import { CanvasProps } from '@/canvas/types'

/**
 * @component Canvas
 * @description - react component wrapping a HTMLCanvasElement and CanvasEngine
 * @param {CanvasProps} props - props for the Canvas component
 */

export const Canvas = (props: CanvasProps) => {

  const engine = useRef() as MutableRefObject<CanvasEngine>
  const canvas = useRef() as MutableRefObject<HTMLCanvasElement>
  const container = useRef() as MutableRefObject<HTMLDivElement>
  const displayStyle: React.CSSProperties = { display: 'block', position: 'absolute' }

  const [size, setSize] = useState({ width: 0, height: 0 })

  const { className, style, children, ...rest } = props

  // Setup on mount

  useEffect(() => {

    setSize({
      width: container.current.clientWidth,
      height: container.current.clientHeight
    })

    const observer = new ResizeObserver(entries => {

      for (let entry of entries) {

        if (entry.contentRect) setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        })

      }

    })

    observer.observe(container.current)

    return () => {
      observer.disconnect()
    }

  }, [])

  useLayoutEffect(() => {

    console.log(rest)

    engine.current = new CanvasEngine({
      ...rest,
      canvas: canvas.current
    })

    return () => {
      engine.current.teardown()
    }

  }, [])

  return (
    <div
      ref={container}
      className={className}
    >

      <canvas
        ref={canvas}
        width={size.width}
        height={size.height}
        style={{
          ...displayStyle,
          ...style
        }}
      />

    </div>
  )

}
