import * as React from 'react'
import { motion, useTransform, MotionValue } from 'framer-motion'
import { easing } from 'popmotion'

interface HeadlineProps {
  progress: MotionValue,
  projectName: string,
  clientName: string
}

export const Headline = (props: HeadlineProps) => {

  return (
    <motion.div className="headline">
      <h1 className="headline-project">{ props.projectName }</h1>
      <span className="headline-for">for</span>
      <h2 className="headline-client">{ props.clientName }</h2>
    </motion.div>
  )

}
