import { uid } from 'uid'
import { composerState } from './state'
import { ComposerSource } from './composerSource'
import { getSource, getSources, addNamespace } from './utils'

/**
 * @class Composer
 * @description - used to orchestrate imperitive loading of many canvases
 */

export class Composer {
  namespace: string
  hookCallback: Function

  /**
   * @constructor
   * @description - instantiate composer
   * @param {string} namespace - composer namespace
   * @param {Function} callback - composer callback
   */

  constructor (namespace: string, callback: Function) {
    this.namespace = namespace
    addNamespace(this.namespace)
    this.hookCallback = callback
    this.onInit()
  }

  /**
   * @method register
   * @description - called by CanvasEngine to start receiving orders from composer
   * @param {string} id - source id from .source.js
   * @param {Function} callback - callback from CanvasEngine to load source
   * @public
   */

  public register(id: string, callback: Function): void {

    if (composerState.sources[this.namespace][id]) return // don't re-register sources

    composerState.sources[this.namespace][id] = new ComposerSource(id, callback)

  }

  /**
   * @method batchLoaded
   * @description - react hook callback whenever a batch is loaded
   * @param {string} id - source id from .source.js
   * @param {number} maturation - a number between 0 and 1 indicating the loading progress
   * @public
   */

  public batchLoaded(id: string, maturation: number): void {

    const source = getSource(this.namespace, id)

    if (source) {

      source.setMaturation(maturation)

      this.hookCallback(
        getSources(this.namespace)
      )

    }

  }

  /**
   * @method onInit
   * @description - called to kick off a chain of loading
   * @param {Function} callback - callback to call after composer instantiation
   * @public
   */

  public onInit (): void {
    setTimeout(() => this.hookCallback(
      getSources(this.namespace)
    ), 0)
  }

}
