/**
 * ComposerSource
 * @description - represents a source for use with Composer
 * @param {string} id - a source id provided from canvas engine
 * @param {Function} callback - callback function provided from canvas engine
 */

export class ComposerSource {
  id: string
  maturation: number
  beganLoading: boolean
  private _callback: Function

  /**
   * @constructor
   * @description - instantiate the source
   */

  constructor(id: string, callback: Function) {
    this.id = id
    this.maturation = 0
    this.beganLoading = false
    this._callback = callback
  }

  /**
   * @method setMaturation
   * @description - updates maturation when a batch has loaded
   * @public
   */

  public setMaturation(m: number): void {
    this.maturation = m
  }

  /**
   * @method loadSource
   * @description - called to start loading the source
   */

  public loadSource(): void {

    if (this.beganLoading) return

    this.beganLoading = true

    setTimeout(() => this._callback(), 0)

  }

  /**
   * @property {boolean} hasLoaded
   * @description - getter indicating whether the source has finished loading
   */

  get hasLoaded(): boolean {
    return this.maturation === 1
  }

}
