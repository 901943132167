import * as React from 'react'
import { Timestamp } from './Timestamp'
import { Canvas, CanvasProps, Composer } from '@/canvas'
import { Headline } from '../Headline'
import { MotionValue, motion, useTransform } from 'framer-motion'
import { Transformers } from '../../js/getTransformers'

const MemoizedCanvas = React.memo(Canvas)

interface ReelItemProps {
  progress: MotionValue,
  source: any,
  composer: Composer,
  winWidth: number,
  winHeight: number,
  transformers: Transformers
  projectMeta: {
    timestamp: string,
    projectName: string,
    clientName: string,
  }
}

const ReelItem = (props: ReelItemProps) => {

  const {
    progress,
    source,
    composer,
    projectMeta,
    transformers
  } = props

  const containerStyle = {
    opacity: useTransform(
      progress,
      ...transformers.fade
    )
  }

  const canvasProps: CanvasProps = {
    progress: useTransform(
      progress,
      ...transformers.playback
    ),
    source: {
      ...source
    },
    composer,
    debug: true, //!!process.env.GATSBY_DEBUG,
    frameRate: 1,
    fillMode: 'cover',
    baseURL: `${ document.location.origin }/static/tar`
  }

  const headlineStyle = {
    opacity: useTransform(progress,
      ...transformers.headline.opacity
    ),
    y: useTransform(progress,
      ...transformers.headline.y
    )
  }

  return (
    <motion.div>

      <motion.div style={containerStyle}>

        <MemoizedCanvas
          className="canvas"
          {...canvasProps}
        />

      </motion.div>

      <motion.div
        style={headlineStyle}
        className="project-details"
      >

        <Timestamp
          start={projectMeta.timestamp}
          progress={progress}
          transformer={transformers.playback}
          duration={source.duration}
        />

        <Headline
          progress={progress}
          {...projectMeta}
        />

      </motion.div>

    </motion.div>
  )

}

export default ReelItem
