import { zipWith } from 'lodash'
import { CanvasSource } from '@/canvas'

export type FadeTransformer = [
  [number, number, number, number],
  [number, number, number, number]
]

export type PlaybackTransformer = [
  [number, number],
  [number, number]
]

export type HeadlineTransformer = {
  y: [
    [number, number],
    [number, number]
  ],
  opacity: [
    [number, number, number, number],
    [number, number, number, number]
  ]
}

export interface Transformers {
  fade: FadeTransformer,
  playback: PlaybackTransformer,
  headline: HeadlineTransformer
}

interface GetTransformerOpts {
  yTransform?: [string, string]
}

export const getTransformers = (projects: CanvasSource[], opts?: GetTransformerOpts) => {

  const totalDuration = projects.reduce((d, m) => d + m.duration, 0)

  // video playback keyframes

  const playback: PlaybackTransformer[] = projects.reduce((all, project, i) => {

    const lastEnd = all.length ? all[i - 1][0][1] : 0
    const start = lastEnd
    const end = start + project.duration / totalDuration

    const transformer = [[start, end], [0, 1]]

    return [...all, transformer]

  }, [])

  // video fade keyframes

  const fade: FadeTransformer[] = projects.reduce((all, project, i, projects) => {

    const offset = all.length ? all[i - 1][0][3] : 0

    const entering = offset
    const entered = offset + (1000 / totalDuration)
    const exiting = offset + ((project.duration - 1000) / totalDuration)
    const exited = offset + (project.duration / totalDuration)

    const transformer = [
      [entering, entered, exiting, exited],
      [0, 1, 1, 0]
    ]

    return [...all, transformer]

  }, [])

  // headline keyframes

  const headline: HeadlineTransformer[] = projects.reduce((all, project, i, projects) => {

    const last = all[i - 1]
    const offset = last ? last.opacity[0][3] : 0
    const entering = offset + (200 / totalDuration)
    const entered = offset + (600 / totalDuration)
    const exiting = offset + ((project.duration - 400) / totalDuration)
    const exited = offset + ((project.duration) / totalDuration)

    const y = [[entering, exited], (opts.yTransform || [0, 1])]
    const opacity = [[entering, entered, exiting, exited], [0, 1, 1, 0]]

    return [...all, { y, opacity }]

  }, [])

  // return

  return zipWith(playback, fade, headline, (a, b, c) => {

    return {
      playback: a,
      fade: b,
      headline: c
    }

  })

}
