import * as React from 'react'
import { useState, useEffect } from 'react'
import { useTransform, MotionValue } from 'framer-motion'

const getSeconds = (startTime: string, time: number) => {

  const t = startTime.split(':')
  const s = ((+t[0]) * 60 * 60 + (+t[1]) * 60 + (+t[2])) * 1000

  return s + time

}

const getTimestamp = (ms: number) => {

  return new Date(ms)
    .toISOString()
    .slice(14, 22)

}

interface TimestampProps {
  start: string,
  progress: MotionValue,
  transformer: number[][],
  duration: number
}

export const Timestamp = (props: TimestampProps) => {

  const { progress, transformer, duration, start } = props
  const [timestamp, setTimestamp] = useState(start)

  const transformed = useTransform(
    progress,
    ...transformer
  )

  useEffect(() => {

    return transformed.onChange((v: number) => {
      setTimestamp(
        getTimestamp(
          getSeconds(start, v * duration)
        )
      )
    })

  })

  return <span className="timestamp">{ timestamp }</span>

}
