import * as React from 'react'
import { Scrollable } from 'components/Scrollable'
import { Reel } from 'components/Reel'
import { WindowSizeProvider } from 'components/WindowSizeContext'
import { PausePlay } from 'components/PausePlay'
import { useIntroContext } from 'components/IntroContext'
import { Cursor } from 'components/Cursor'

const IndexPage = () => {

  const { setIsAbout } = useIntroContext()

  React.useEffect(() => {
    setIsAbout(false)
  }, [])

  return (
    <WindowSizeProvider>
      <Scrollable>

        <Reel />

        <Cursor />

        <PausePlay />

      </Scrollable>
    </WindowSizeProvider>
  )

}

export default IndexPage
