import * as React from 'react'
import { useState, useEffect } from 'react'
import Portrait from './Portrait'
import Landscape from './Landscape'
import { MotionValue } from 'framer-motion'
import { useSize } from '../../js/hooks'

export interface ReelCanvasInterface {
  winHeight: number
  winWidth: number
  progress: MotionValue<number>
  baseUrl: string
}

const getOrientation = size => {
  return size.winWidth > size.winHeight ?
  'landscape' : 'portrait'
}

export const Reel = () => {

  const { size } = useSize()

  if (!size) return (<></>)

  if (getOrientation(size) === 'portrait') return (
    <div className="canvas-container" key="portrait" style={{ height: `${ size.winHeight }px` }}>
      <Portrait {...size} />
    </div>
  )

  if (getOrientation(size) === 'landscape') return (
    <div className="canvas-container" key="landscape">
      <Landscape {...size} />
    </div>
  )

}
