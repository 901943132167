import * as React from 'react'
import { useComposer } from '@/canvas'
import ReelItem from './ReelItem'
import projects from '../../js/source/portrait'
import { useProgress } from '../Scrollable'
import { ReelCanvasInterface } from './index'
import { getTransformers } from '../../js/getTransformers'
import { getContent } from '../../js/queries'
import { Loader } from '../Loader'
import { useIntroContext } from '../IntroContext'

const transformers = getTransformers(projects, {
  yTransform: ['200%', '-200%']
})

/**
 * PortraitReel
 */

const Portrait = (props: ReelCanvasInterface) => {

  const { reelItems } = getContent()
  const { progress, startPlayProgress, resetPlayProgress } = useProgress()
  const { showIntro, showLogo, setShowIntro, didShowIntro, setDidShowIntro, setShowLogo, setShowNavLink } = useIntroContext()

  const composer = useComposer('portrait', (sources) => {

    if (!sources.length) return

    const someBeganLoading = sources
      .some(source => source.beganLoading)

    // const allHasLoaded = sources
    //   .every(source => source.hasLoaded)

    if (!someBeganLoading) {

      resetPlayProgress()

      const firstSource = sources[0]

      firstSource.loadSource()

      setShowIntro(true)

      return

    }

    if (sources[0].hasLoaded) {

      setTimeout(() => {
        startPlayProgress()
      }, showLogo ? 400 : 800)

      setShowNavLink(true)
      setShowLogo(true)
      setShowIntro(false)
      setDidShowIntro(true)

    }

    sources.forEach((source, index) => {

      const m = (index === 0) ? 1 : 0.5

      if (source.maturation >= m) {

        const nextSource = sources[index + 1]

        if (nextSource !== undefined) {
          nextSource.loadSource()
        }

      }

    })

  })

  return (

    <div>

      { projects.map((project, i) => {

        const {
          projectName,
          clientName,
          portraitTimestamp
        } = reelItems[i]

        return (

          <ReelItem
            composer={composer}
            progress={progress}
            source={project}
            transformers={transformers[i]}
            key={project.id}
            winWidth={props.winWidth}
            winHeight={props.winHeight}
            projectMeta={{
              projectName,
              clientName,
              timestamp: portraitTimestamp
            }}
          />

        )

      }) }

      <Loader show={ !didShowIntro && showIntro } />

    </div>

  )

}

export default Portrait
