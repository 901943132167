import { composerState } from './state'
import { ComposerSource } from './composerSource'

/**
 * @function addNamespace
 * @description - adds a namespace to composer state
 * @arg {string} namespace - uuid, auto created in composer consructor
 */

export const addNamespace = (namespace: string): void => {
  composerState.sources[namespace] = {}
}

/**
 * @function getSource
 * @description - get source by id
 * @arg {string} id - source id
 */

export const getSource = (namespace: string, id: string): ComposerSource => {
  return composerState.sources[namespace][id]
}

/**
 * @function getSources
 * @description - get sources as array
 */

export const getSources = (namespace: string): ComposerSource[] => {
  return Object.values(composerState.sources[namespace])
}
