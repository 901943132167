import * as React from 'react'
import { useComposer } from '@/canvas'
import ReelItem from './ReelItem'
import projects from '../../js/source/landscape'
import { getTransformers } from '../../js/getTransformers'
import { useProgress } from '../Scrollable'
import { ReelCanvasInterface } from './index'
import { getContent } from '../../js/queries'
import { Loader } from '../Loader'
import { useIntroContext } from '../IntroContext'

const transformers = getTransformers(projects, {
  yTransform: ['800%', '-800%']
})

/**
 * PortraitReel
 */

const Landscape = (props: ReelCanvasInterface) => {

  const { reelItems } = getContent()
  const { progress, startPlayProgress, resetPlayProgress } = useProgress()
  const { showIntro, didShowIntro, setDidShowIntro, showLogo, setShowIntro, setShowLogo, setShowNavLink } = useIntroContext()

  const composer = useComposer('landscape', (sources) => {

    if (!sources.length) return

    const someBeganLoading = sources
      .some(source => source.beganLoading)

    // const allHasLoaded = sources
    //   .every(source => source.hasLoaded)

    if (!someBeganLoading) {

      resetPlayProgress()

      const firstSource = sources[0]

      firstSource.loadSource()

      setShowIntro(true)

      return

    }

    if (sources[0].hasLoaded && !sources[1].beganLoading) {

      setTimeout(() => {
        startPlayProgress()
      }, showLogo ? 400 : 800)

      setShowNavLink(true)
      setShowLogo(true)
      setShowIntro(false)
      setDidShowIntro(true)

    }

    sources.forEach((source, index) => {

      const m = (index === 0) ? 1 : 0.5

      if (source.maturation >= m) {

        const nextSource = sources[index + 1]

        if (nextSource !== undefined) {
          nextSource.loadSource()
        }

      }

    })

  })

    // if (!firstSource.beganLoading) {
    //   firstSource.loadSource()
    // } else if (firstSource.hasLoaded) {
    //
    //   setShowIntro(false)
    //   setShowLogo(true)
    //
    //   setTimeout(() => {
    //     //startPlayProgress()
    //   }, 1000)
    //
    //   sources.forEach((source, index) => {
    //
    //     const m = (index === 0) ? 1 : 0.5
    //
    //     if (source.maturation >= m) {
    //
    //       const nextSource = sources[index + 1]
    //
    //       if (nextSource !== undefined) {
    //         nextSource.loadSource()
    //       }
    //
    //     }
    //
    //   })
    //
    // }

  //})

  return (

    <div>

      { projects.map((project, i) => {

        const {
          projectName,
          clientName,
          landscapeTimestamp
         } = reelItems[i]

        return (

          <ReelItem
            composer={composer}
            progress={progress}
            source={project}
            transformers={transformers[i]}
            key={project.id}
            winWidth={props.winWidth}
            winHeight={props.winHeight}
            projectMeta={{
              projectName,
              clientName,
              timestamp: landscapeTimestamp
            }}
          />

        )

      }) }

      <Loader show={ !didShowIntro && showIntro } />

    </div>

  )

}

export default Landscape
