import { IndexedImage, Scrubber } from '@/canvas/types'

/**
 * @function scrubber
 * @description - factory function to create scrubber
 * @param {IndexedImage[]} availFrames - array of indexed images ready to render
 * @param {number} totalFrames - number of maximum expected frames
 * @returns {Scrubber}
 */

export const scrubber = (
  availFrames: IndexedImage[],
  totalFrames: number
): Scrubber => {

  const getDiff = (frame: IndexedImage, progress: number) => {
    return Math.abs((frame.index / totalFrames) - progress)
  }

  const [firstFrame, ...restFrames] = availFrames

  const scrub = (progress: number) => restFrames.reduce((best, frame) => {

    const bestDiff = getDiff(best, progress)
    const frameDiff = getDiff(frame, progress)

    if (bestDiff > frameDiff) return frame

    return best

  }, {
    ...firstFrame
  })

  return scrub

}
