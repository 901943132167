import { uid } from 'uid'
import { LoadImagesOpts, LoadTarballOpts, IndexedBlob } from '@/canvas/types'
import { createBatches } from './batching'
// import worker from './loader.worker'
// import { URL as NodeURL } from 'url'

// import * as workerString from '@/../lib/worker.min.js'

/**
 * Create worker instance
 */
//
// let worker: Worker
// let workerUrl: NodeURL

// (function (): void {
//
//   //if (typeof window === 'undefined') return
//
//   //const workerBlob = new Blob([workerString.default])
//   //workerUrl = URL.createObjectURL(workerBlob)
//
//
//
//
//
//
//   workerUrl = new URL('./worker.ts', import.meta.url)
//
//   worker = new Worker(workerUrl)
//
// })()


// const workerUrl = new URL('/worker.min.js', import.meta.url)

//const worker = new Worker(workerUrl)
//const worker = new Worker('/worker.min.js')

//console.log(worker)

// let worker
//
// (function (): void {
//
//   if (typeof window === 'undefined') return
//
//   console.log('hej')
//
//   worker = new Worker('/worker.min.js')
//
// })


/**
 * @typedef TeardownFunction
 * @description - call to unsubsribe to workers
 */

type TeardownFunction = Function

/**
 * @function loadTarball
 * @arg {LoadTarballOpts} opts - TODO:
 * @arg {Function} callback - TODO:
 * @returns {TeardownFunction}
 */

export const loadTarball = (opts: LoadTarballOpts, callback: Function): TeardownFunction => {

  const _id = uid()

  const worker = new Worker('/worker.min.js')

  worker.addEventListener('message', handleTar)

  worker.postMessage({
    id: _id,
    action: 'loadTarball',
    ...opts
  })

  /**
   * @function handleTar
   * @param {MessageEvent} e - message event from web worker
   */

  async function handleTar(e: MessageEvent) {

    const { id, action, indexedBlobs, maturation } = e.data

    if (id !== _id) return
    if (action !== 'tarballLoaded') return

    const queue = indexedBlobs.map((indexedBlob: IndexedBlob) => () => {

      return new Promise((resolve) => {

        let url: string
        const image = new Image()
        const { index, blob } = indexedBlob

        setTimeout(() => {
          url = URL.createObjectURL(blob)
          image.src = url
        }, 0)

        image.onload = () => {
          URL.revokeObjectURL(url)
          resolve({ image, index })
        }

        image.onerror = () => {
          URL.revokeObjectURL(url)
          resolve({ image: false, index })
        }

      })

    })

    let validImages = []

    for (const promise of queue) {
      validImages.push(await promise())
    }

    callback(validImages, maturation)

    // Promise.all(queue).then((validImages) => {
    //   callback(validImages, maturation)
    // })

  }

  return () => {
    console.log('unsubscribe to workers')
    worker.removeEventListener('message', handleTar)
  }

}
