import { useMemo } from 'react'
import { Composer } from '@/canvas/composer/composer'

/**
 * @function useComposer
 * @description - react hook for composing canvas loading
 * @arg {Function} callback - callback
 */

export const useComposer = (namespace: string, callback: Function): Composer => {
  return useMemo(() => new Composer(namespace, callback), [namespace])
}
