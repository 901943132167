/**
 * @function concatPath
 * @description basically just path.join
 * @args {string[]}
 */

export const concatPath = (...args: string[]) => {
  return args.map((part, i) => {
    if (i === 0) {
      return part.trim().replace(/[\/]*$/g, '')
    } else {
      return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
    }
  }).filter(x=>x.length).join('/')
}
