import * as React from 'react'
import { Logo } from './Logo'

interface LoaderProps {
  show: boolean
}

export const Loader = (props: LoaderProps) => {

  const transition = props.show ?
   { ease: [0.25, 1, 0.5, 1], duration: 0.4 } :
   { ease: [0.5, 0, 0.75, 0], duration: 0.4 }

  return (
    <div className="loader">

      <div className="loader__inner">

        <Logo show={props.show} transition={transition} />

      </div>

    </div>
  )

}
