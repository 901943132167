import { FillMode, PositionY, PositionX, CanvasEngineParams, IndexedImage, LoadImagesOpts, LoadTarballOpts } from '@/canvas/types'
import { CanvasSource, Composer } from '@/canvas/types'
import { MotionValue } from 'framer-motion'
import { scrubber } from './scrubber'
import { loadTarball } from './loading'
import { concatPath } from './concatPath'

/**
 * @class CanvasEngineError
 * @description error during CanvasEngine param validation
 * @extends Error
 */

class CanvasEngineError extends Error {

  constructor(msg: string) {
    super(msg)
    this.name = 'CanvasEngineError'
  }

}

/**
 * @function validateParams
 * @description logical param validation uncaught by types
 * @param {CanvasEngineParams} params
 */

const validateParams = (params: CanvasEngineParams) => {

  if (params.disabled && params.composer) {
    throw new CanvasEngineError('property "disabled" cannot be used together with composer')
  }

  if (params.frameRate && (params.frameRate < 0 || params.frameRate > 1)) {
    throw new CanvasEngineError('property "frameRate" must be a float between 0 and 1')
  }

}

/**
 * @class CanvasEngine
 * @description engine for image sequence playback control using a MotionValue
 * @param {CanvasEngineParams} params
 */

export class CanvasEngine {
  scrub: Function
  loadTimer: number
  loadedImages: IndexedImage[]
  context: CanvasRenderingContext2D
  params: CanvasEngineParams
  unsubscribeLoader: Function
  unsubscribeProgress: Function
  fillCoordinates: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ]

  /**
   * @constructor
   * @description it is possible to initiate without params and start it later
   */

  constructor(params?: CanvasEngineParams) {
    if (params) this.init(params)
  }

  /**
   * @property composer
   * @description - alias for provided composer
   */

  get composer(): Composer {
    return this.params.composer
  }

  /**
   * @property canvas
   * @description - alias for the canvas element
   */

  get canvas(): HTMLCanvasElement {
    return this.params.canvas
  }

  /**
   * @property progress
   * @description - a motion value between 0 and 1
   */

  get progress(): MotionValue {
    return this.params.progress
  }

  /**
   * @property source
   * @description - the provided source.js file
   */

  get source(): CanvasSource {
    return this.params.source
  }

  /**
   * @property framesLength
   * @description - alias for the number of frames in the image sequence
   */

  get framesLength(): number {
    return this.source.length || 0
  }

  /**
   * @property mediaRatio
   * @description - the image ratio as a floating number
   */

  get mediaRatio(): number {
    return this.source.width / this.source.height
  }

  /**
   * @property canvasRatio
   * @description - the canvas ratio as a floating number
   */

  get canvasRatio(): number {
    return this.canvas.width / this.canvas.height
  }

  /**
   * @property fillCoordinates
   * @description - coordinates for canvas.fillRect
   */

  setFillCoordinates(): void {

    let canvas = [0, 0, 0, 0]
    const image = [0, 0, this.source.width, this.source.height]

    // Contain

    if (this.params.fillMode === 'contain') {

      if (this.mediaRatio > this.canvasRatio) {

        let y = 0

        const { height, width } = this.canvas
        const calcHeight = width / this.mediaRatio

        switch (this.params.positionY) {
          case 'center':
            y = (height - calcHeight) / 2
            break
          case 'bottom':
            y = height - calcHeight
            break
        }

        canvas = [0, y, width, calcHeight]

      }

      if (this.mediaRatio <= this.canvasRatio) {

        let x = 0

        const { height, width } = this.canvas
        const calcWidth = height * this.mediaRatio

        switch (this.params.positionX) {
          case 'center':
            x = (width - calcWidth) / 2
            break
          case 'right':
            x = width - calcWidth
        }

        canvas = [x, 0, calcWidth, height]

      }

    }

    // Cover

    if (this.params.fillMode === 'cover') {

      // Canvas is narrower than source

      if (this.mediaRatio > this.canvasRatio) {
        const x = (this.canvas.width - (this.canvas.height * this.mediaRatio)) / 2
        canvas = [x, 0, this.canvas.height * this.mediaRatio, this.canvas.height]
      }

      // Source is wider than canvas

      if (this.mediaRatio <= this.canvasRatio) {
        const y = (this.params.canvas.height - (this.canvas.width / this.mediaRatio)) / 2
        canvas = [0, y, this.canvas.width, this.canvas.width / this.mediaRatio]
      }

    }

    // return [...image, ...canvas]

    const rawCoordinates = [...image, ...canvas]

    this.fillCoordinates = rawCoordinates.map(num => Math.round(num))

  }

  /**
   * @method init
   * @description - instantiate engine
   * @public
   */

  public init(params: CanvasEngineParams): void {

    // Remove old scrubber in case we are reinstantiating the canvas

    if (this.scrub) {
      this.scrub = undefined
    }

    // Setup canvas

    console.log('canvas engine init')

    this.loadedImages = []

    // HTMLCanvasElement.prototype.getContext = function (orig) {
    //    return function (type) {
    //       return type !== "webgl2" ? orig.apply(this, arguments) : null
    //    }
    // }(HTMLCanvasElement.prototype.getContext)

    this.context = params.canvas.getContext('2d', {
      alpha: false
    })

    // Setup params

    validateParams(params)

    const paramDefaults = {
      frameRate: 1,
      batchDepth: 1,
      loadedImages: [],
      fillMode: 'contain' as FillMode,
      positionY: 'center' as PositionY,
      positionX: 'center' as PositionX,
      batchLoaded: () => {}
    }

    this.params = {
      ...paramDefaults,
      ...params
    }

    // Initiate source

    if (!this.params.disabled && !this.params.debug && !this.params.composer) {

      // Wait 100ms because the component often re-renders a few times
      // This timeout is cleared in engine.teardown

      this.loadTimer = setTimeout(() => {

        this.setupSource(params.source, params.baseURL)

        this.unsubscribeProgress = this.params.progress.onChange(v => {
          this.render(v)
        })

      }, 100) as unknown as number

    }

    // Register composer

    if (params.composer) {

      const source = this.params.source

      this.composer.register(source.id, () => {

        // Wait 100ms because the component often re-renders a few times
        // This timeout is cleared in engine.teardown

        this.loadTimer = setTimeout(() => {

          this.setupSource(params.source, params.baseURL)

          this.unsubscribeProgress = this.params.progress.onChange(v => {
            this.render(v)
          })

        }, 100) as unknown as number

      })

    }

    // Initiate debug

    if (this.params.debug) {
      this.debugRender(this.progress.get() || 0)
      this.unsubscribeProgress = this.params.progress.onChange(v => this.debugRender(v))
    }

  }

  /**
   * @method teardown
   * @description - unsubscribe to listeners etc
   * @public
   */

  public teardown(): void {

    this.canvas.width = 1
    this.canvas.height = 1
    this.context.clearRect(0, 0, 1, 1)

    if (this.loadTimer) {
      clearTimeout(this.loadTimer)
    }

    if (this.unsubscribeLoader) {
      this.unsubscribeLoader()
    }

    if (this.unsubscribeProgress) {
      this.unsubscribeProgress()
    }

  }

  /**
   * @method setupTarball
   * @description - loads tarball, called by setupSource
   * @private
   */

  private setupSource(source: CanvasSource, baseURL: string): void {

    const self = this

    // Add base URL

    const tarUrls = source.tar.map(tar => concatPath(baseURL, tar))

    const opts: LoadTarballOpts = {
      //id: source.id,
      length: source.length,
      tarUrls
    }

    this.unsubscribeLoader = loadTarball(opts, (newImages: IndexedImage[], maturation: number) => {

      // Gets called for each batch

      const nextImages: IndexedImage[] = [...self.loadedImages, ...newImages]

      self.loadedImages = nextImages
        .sort((a, b) => a.index - b.index)
        .filter(img => img.image !== false) // filter out false

      self.scrub = scrubber(self.loadedImages, self.framesLength)

      // this.render(this.progress.get())

      if (this.composer) {
        this.composer.batchLoaded(source.id, maturation)
      }

      if (this.params.batchLoaded) {
        this.params.batchLoaded(maturation)
      }

    })

  }

  /**
   * @method debugRender
   * @description - renders the canvas with a numeric progress for debugging
   * @private
   */

  private debugRender(progress: number): void {

    const number = progress ? Math.round(progress * 100).toString() : '0'
    const percentage = `${ number }%`

    this.context.clearRect(0, 0, this.params.canvas.width, this.params.canvas.height)
    this.context.fillText(percentage, 12, 20)

  }

  /**
   * @method render
   * @description - renders an image to the canvas given a number between 0 and 1
   * @private
   */

  private render(progress: number): void {

    if (!this.scrub) return

    if (!this.fillCoordinates) {
      this.setFillCoordinates()
    }

    try {

      const { image } = this.scrub(progress)

      this.context.drawImage(image, ...this.fillCoordinates)

    } catch (e) {

      console.log(e)

    }

  }

}
