import { ComposerSource } from './composerSource'

/**
 * @typedef ComposerSourceRecord
 * @description - tuple of string identifier and ComposerSource
 */

export type ComposerSourceRecord = Record<string, ComposerSource>
export type ComposerRecord = Record<string, ComposerSourceRecord>

/**
 * @interface ComposerState
 * @description - composer state, containing global and namespaced sources
 */

export interface ComposerState {
  sources: ComposerRecord
}

/**
 * @constant composerState
 * @description - state singleton
 */

export const composerState: ComposerState = {
  sources: {}
}
